<template>
  <div :class="$style.common">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>

    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <DropdownBlock
        title="Шапка сайта"
        :is-open="isShowHeaderBlock"
        :class="$style.dropdownBlock"
        @handle-show="isShowHeaderBlock = !isShowHeaderBlock"
      >
        <el-form-item
          label="Кнопка обратной связи"
          prop="header.feedbackModalTitle"
        >
          <el-input v-model="form.header.feedbackModalTitle" />
        </el-form-item>
        <el-form-item label="Номер телефона" prop="header.phone.phone">
          <el-input v-model="form.header.phone.phone" />
        </el-form-item>
        <el-form-item label="Ссылка телефона" prop="header.phone.link">
          <el-input v-model="form.header.phone.link" />
        </el-form-item>
        <el-form-item label="Время работы" prop="header.workingHours">
          <el-input
            v-model="form.header.workingHours"
            :rows="2"
            type="textarea"
            placeholder="Введите время работы"
          />
        </el-form-item>
        <h3>Ссылки в меню</h3>
        <h5>
          Ссылки указываются в относительном формате, например: /promotions,
          /catalogue/...
        </h5>
        <Container
          @drop="onDropMenu"
          :non-drag-area-selector="'.drag-disabled'"
        >
          <Draggable
            v-for="(menuItem, index) in form.header.navigation"
            :key="index"
          >
            <div :class="$style.menuBlock">
              <div :class="$style.menuItem">
                <div>
                  <el-form-item
                    label="Название страницы"
                    :prop="'header.navigation.' + index + '.text'"
                    :rules="rules.header.navigation.text"
                    class="drag-disabled"
                  >
                    <el-input v-model="menuItem.text" />
                  </el-form-item>
                  <el-form-item
                    label="Ссылка"
                    :prop="'header.navigation.' + index + '.link'"
                    :rules="rules.header.navigation.link"
                    class="drag-disabled"
                  >
                    <el-input v-model="menuItem.link" />
                  </el-form-item>
                </div>

                <div :class="$style.removeElem">
                  <el-button
                    type="danger"
                    plain
                    circle
                    icon="el-icon-delete"
                    :class="$style.remove"
                    @click="removeSection(index)"
                  />
                </div>
                <div>
                  <Icon name="draggable" :class="$style.icon" />
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
        <div :class="$style.addElem">
          <el-button
            size="small"
            type="primary"
            :class="$style.button"
            @click="addMenuElem()"
            >Добавить пункт меню</el-button
          >
        </div>
      </DropdownBlock>

      <DropdownBlock
        title="Блок отзывы"
        :is-open="isShowFeedbacksBlock"
        :class="$style.dropdownBlock"
        @handle-show="isShowFeedbacksBlock = !isShowFeedbacksBlock"
      >
        <el-form-item label="Заголовок" prop="reviewsSection.title">
          <el-input v-model="form.reviewsSection.title" />
        </el-form-item>
        <div :class="$style.addElem">
          <h3>Отзывы</h3>
          <el-button
            size="small"
            type="primary"
            :class="$style.button"
            @click="addReviewElem()"
            >Добавить отзыв</el-button
          >
        </div>
        <Container
          @drop="onDropReviews"
          :non-drag-area-selector="'.drag-disabled'"
        >
          <Draggable
            v-for="(menuItem, index) in form.reviewsSection.reviews"
            :key="index"
          >
            <div :class="$style.reviewers">
              <el-button
                type="danger"
                plain
                circle
                icon="el-icon-delete"
                :class="$style.remove"
                @click="removeItem('review', index)"
              />
              <div>
                <el-form-item
                  label="Имя"
                  class="drag-disabled"
                  :prop="'reviewsSection.reviews.' + index + '.name'"
                  :rules="rules.reviewsSection.reviews.name"
                >
                  <el-input v-model="menuItem.name" />
                </el-form-item>
                <el-form-item
                  label="Место работы"
                  class="drag-disabled"
                  :prop="'reviewsSection.reviews.' + index + '.jobTitle'"
                  :rules="rules.reviewsSection.reviews.jobTitle"
                >
                  <el-input v-model="menuItem.jobTitle" />
                </el-form-item>
                <el-form-item
                  label="Отзыв"
                  class="drag-disabled"
                  :prop="'reviewsSection.reviews.' + index + '.text'"
                  :rules="rules.reviewsSection.reviews.text"
                >
                  <el-input
                    v-model="menuItem.text"
                    :rows="7"
                    type="textarea"
                    placeholder="Введите отзыв"
                  />
                </el-form-item>
              </div>

              <div>
                <div :class="$style.addElem">
                  <h3>Ссылки на соцсети</h3>
                  <el-button
                    size="small"
                    type="primary"
                    :class="$style.button"
                    @click="addIconForReview(index)"
                    >Добавить ссылку</el-button
                  >
                </div>
                <Container
                  @drop="onDropReviewSocials(index, $event)"
                  :non-drag-area-selector="'.drag-disabled'"
                >
                  <Draggable v-for="(icon, ind) in menuItem.socials" :key="ind">
                    <div :class="$style.draggableSocials">
                      <el-form-item
                        label="Ссылка"
                        label-width="5rem"
                        class="drag-disabled"
                        :prop="
                          'reviewsSection.reviews.' +
                          index +
                          '.socials.' +
                          ind +
                          '.link'
                        "
                        :rules="rules.reviewsSection.reviews.socials.link"
                      >
                        <el-input v-model="icon.link" />
                      </el-form-item>
                      <el-form-item
                        label="Иконка"
                        label-width="5rem"
                        class="drag-disabled"
                      >
                        <el-select
                          v-model="icon.iconName"
                          filterable
                          placeholder="Выберите иконку"
                          :popper-class="$style.iconOptions"
                        >
                          <el-option
                            v-for="(item, index) in iconOptions"
                            :key="index"
                            :label="item"
                            :value="item"
                          >
                            <Icon :name="item" />
                            {{ item }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <div :class="$style.removeButton">
                        <el-button
                          type="danger"
                          plain
                          circle
                          icon="el-icon-delete"
                          :class="$style.remove"
                          @click="removeReviewSocials(menuItem.socials, ind)"
                        />
                      </div>
                      <div :class="$style.dragIcon">
                        <Icon name="draggable" :class="$style.icon" />
                      </div>
                    </div>
                  </Draggable>
                </Container>
              </div>

              <div :class="$style.uploaderWrapper">
                <Uploader
                  without-caption
                  :limit="1"
                  :files="
                    menuItem.image
                      ? [
                          {
                            original: form.reviewsSection.reviews[index].image,
                          },
                        ]
                      : []
                  "
                  @upload="uploadImages($event, index)"
                />
                <div :class="$style.centerIcon">
                  <Icon name="draggable" :class="$style.icon" />
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
      </DropdownBlock>

      <DropdownBlock
        title="Блок подвала"
        :is-open="footerBlock.isShowBlock"
        :class="$style.dropdownBlock"
        @handle-show="footerBlock.isShowBlock = !footerBlock.isShowBlock"
      >
        <DropdownBlock
          title="Информация"
          :is-open="footerBlock.isShowInformation"
          inner-block
          @handle-show="
            footerBlock.isShowInformation = !footerBlock.isShowInformation
          "
        >
          <el-form-item label="О компании" prop="footer.companyInfo">
            <el-input v-model="form.footer.companyInfo" />
          </el-form-item>
          <el-form-item label="ОГРНИП" prop="footer.ogrnip">
            <el-input v-model="form.footer.ogrnip" />
          </el-form-item>
          <el-form-item label="ИНН" prop="footer.inn">
            <el-input v-model="form.footer.inn" />
          </el-form-item>
        </DropdownBlock>

        <DropdownBlock
          title="Иконки соцсетей"
          :is-open="footerBlock.isShowSocials"
          inner-block
          @handle-show="footerBlock.isShowSocials = !footerBlock.isShowSocials"
        >
          <Container
            @drop="onDropFooterSocials"
            :non-drag-area-selector="'.drag-disabled'"
          >
            <Draggable v-for="(icon, ind) in form.footer.socials" :key="ind">
              <div :class="$style.draggableSocials">
                <el-form-item
                  label="Ссылка"
                  class="drag-disabled"
                  :prop="'footer.socials.' + ind + '.link'"
                  :rules="rules.footer.socials.link"
                >
                  <el-input v-model="icon.link" />
                </el-form-item>
                <el-form-item
                  label="Иконка"
                  class="drag-disabled"
                  :prop="'footer.socials.' + ind + '.iconName'"
                  :rules="rules.footer.socials.iconName"
                >
                  <el-select
                    v-model="icon.iconName"
                    filterable
                    placeholder="Выберите иконку"
                    :popper-class="$style.iconOptions"
                  >
                    <el-option
                      v-for="(item, index) in iconOptions"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                      <Icon :name="item" />
                      {{ item }}
                    </el-option>
                  </el-select>
                </el-form-item>
                <div :class="$style.removeButton">
                  <el-button
                    type="danger"
                    plain
                    circle
                    icon="el-icon-delete"
                    :class="$style.remove"
                    @click="removeFooterSocials(ind)"
                  />
                </div>
                <div :class="$style.dragIcon">
                  <Icon name="draggable" :class="$style.icon" />
                </div>
              </div>
            </Draggable>
          </Container>
          <div :class="$style.addElem">
            <el-button
              size="small"
              type="primary"
              :class="$style.button"
              @click="addIconElem()"
              >Добавить иконку</el-button
            >
          </div>
        </DropdownBlock>

        <DropdownBlock
          title="Кнопки"
          :is-open="footerBlock.isShowButtons"
          inner-block
          @handle-show="footerBlock.isShowButtons = !footerBlock.isShowButtons"
        >
          <Container
            @drop="onDrop($event, 'footer-button')"
            :non-drag-area-selector="'.drag-disabled'"
            :class="$style.body"
          >
            <Draggable
              v-for="(item, ind) in form.footer.specialButtons"
              :key="ind"
              :class="$style.row"
            >
              <div :class="$style.title">
                <Icon name="draggable" :class="$style.icon" />
                <h3>{{ ind + 1 }}.</h3>
              </div>
              <div :class="$style.content">
                <el-form-item
                  label="Тип кнопки"
                  class="drag-disabled"
                  :prop="'footer.specialButtons.' + ind + '.type'"
                  :rules="rules.footer.specialButtons.type"
                >
                  <el-select v-model="item.type" placeholder="Выбрать">
                    <el-option
                      v-for="i in specialButtonsOptions"
                      :key="i.type"
                      :label="i.text"
                      :value="i.type"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Текст кнопки"
                  class="drag-disabled"
                  :prop="'footer.specialButtons.' + ind + '.text'"
                  :rules="rules.footer.specialButtons.text"
                >
                  <el-input v-model="item.text" />
                </el-form-item>
                <el-form-item
                  v-if="item.type === 'link'"
                  label="Ссылка кнопки"
                  class="drag-disabled"
                  :prop="'footer.specialButtons.' + ind + '.link'"
                  :rules="
                    item.type === 'link' ? rules.footer.specialButtons.link : ''
                  "
                >
                  <el-input v-model="item.link" />
                </el-form-item>
                <el-form-item
                  v-else-if="item.type === 'modal'"
                  label="Тип модального окна"
                  :prop="'footer.specialButtons.' + ind + '.link'"
                  :rules="
                    item.type === 'modal'
                      ? rules.footer.specialButtons.link
                      : ''
                  "
                >
                  <el-select v-model="item.link" placeholder="Выбрать">
                    <el-option
                      v-for="i in modalTypes"
                      :key="i.type"
                      :label="i.text"
                      :value="i.type"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div :class="$style.buttons">
                <el-button
                  type="danger"
                  plain
                  circle
                  icon="el-icon-delete"
                  :class="$style.remove"
                  @click="removeItem('footer-button', ind)"
                />
              </div>
            </Draggable>
          </Container>
          <div :class="$style.addElem">
            <el-button
              size="small"
              type="primary"
              :class="$style.button"
              @click="addItem('footer-button')"
              >Добавить кнопку</el-button
            >
          </div>
        </DropdownBlock>

        <DropdownBlock
          title="Навигационное меню"
          :is-open="footerBlock.isShowTopMenu"
          inner-block
          @handle-show="footerBlock.isShowTopMenu = !footerBlock.isShowTopMenu"
        >
          <Container
            @drop="onDrop($event, 'footer-column-top-menu')"
            :non-drag-area-selector="'.drag-disabled'"
            :class="$style.body"
          >
            <Draggable
              v-for="(item, index) in form.footer.topLinks"
              :key="index"
              :class="$style.row"
            >
              <div :class="$style.title">
                <Icon name="draggable" :class="$style.icon" />
                <h4>{{ index + 1 }}.</h4>
                <div :class="$style.buttons">
                  <Icon
                    name="arrow"
                    @click="
                      form.footer.topLinks[index].isShow =
                        !form.footer.topLinks[index].isShow
                    "
                    :class="[
                      $style.icon,
                      $style.arrow,
                      { [$style.active]: item.isShow },
                      'drag-disabled',
                    ]"
                  />
                </div>
              </div>
              <div v-if="item.isShow">
                <Container
                  @drop="onDrop($event, 'footer-top-menu-link', index)"
                  :non-drag-area-selector="'.drag-disabled'"
                  :class="$style.body"
                >
                  <Draggable
                    v-for="(linkItem, ind) in item.items"
                    :key="ind"
                    :class="$style.row"
                  >
                    <div :class="$style.title">
                      <Icon name="draggable" :class="$style.icon" />
                      <h4>{{ ind + 1 }}.</h4>
                    </div>
                    <div :class="$style.draggableSocials">
                      <el-form-item
                        label="Текст ссылки"
                        class="drag-disabled"
                        :prop="
                          'footer.topLinks.' + index + '.items.' + ind + '.text'
                        "
                        :rules="rules.footer.topLinks.items.text"
                      >
                        <el-input v-model="linkItem.text" />
                      </el-form-item>
                      <el-form-item
                        label="Ссылка"
                        class="drag-disabled"
                        :prop="
                          'footer.topLinks.' + index + '.items.' + ind + '.link'
                        "
                        :rules="rules.footer.topLinks.items.link"
                      >
                        <el-input v-model="linkItem.link" />
                      </el-form-item>
                      <div :class="$style.removeButton">
                        <el-button
                          type="danger"
                          plain
                          circle
                          icon="el-icon-delete"
                          :class="$style.remove"
                          @click="
                            removeItem('footer-top-menu-link', index, ind)
                          "
                        />
                      </div>
                    </div>
                  </Draggable>
                </Container>
                <div :class="$style.addElem">
                  <el-button
                    size="small"
                    type="primary"
                    :class="$style.button"
                    @click="addItem('footer-top-menu-link', index)"
                    >Добавить пункт меню</el-button
                  >
                </div>
              </div>
              <div :class="$style.buttons">
                <el-button
                  type="danger"
                  plain
                  circle
                  icon="el-icon-delete"
                  :class="$style.remove"
                  @click="removeItem('footer-column-top-menu', index)"
                />
              </div>
            </Draggable>
          </Container>
          <div :class="$style.addElem">
            <el-button
              size="small"
              type="primary"
              :class="$style.button"
              @click="addItem('footer-column-top-menu')"
              >Добавить блок</el-button
            >
          </div>
        </DropdownBlock>

        <DropdownBlock
          title="Меню политик"
          :is-open="footerBlock.isShowBottomMenu"
          inner-block
          @handle-show="
            footerBlock.isShowBottomMenu = !footerBlock.isShowBottomMenu
          "
        >
          <Container
            @drop="onDrop($event, 'footer-column-bottom-menu')"
            :non-drag-area-selector="'.drag-disabled'"
            :class="$style.body"
          >
            <Draggable
              v-for="(item, index) in form.footer.bottomLinks"
              :key="index"
              :class="$style.row"
            >
              <div :class="$style.title">
                <Icon name="draggable" :class="$style.icon" />
                <h4>{{ index + 1 }}.</h4>
                <div :class="$style.buttons">
                  <Icon
                    name="arrow"
                    @click="
                      form.footer.bottomLinks[index].isShow =
                        !form.footer.bottomLinks[index].isShow
                    "
                    :class="[
                      $style.icon,
                      $style.arrow,
                      { [$style.active]: item.isShow },
                      'drag-disabled',
                    ]"
                  />
                </div>
              </div>
              <div v-if="item.isShow">
                <Container
                  @drop="onDrop($event, 'footer-bottom-menu-link', index)"
                  :non-drag-area-selector="'.drag-disabled'"
                  :class="$style.body"
                >
                  <Draggable
                    v-for="(linkItem, ind) in item.items"
                    :key="ind"
                    :class="$style.row"
                  >
                    <div :class="$style.title">
                      <Icon name="draggable" :class="$style.icon" />
                      <h4>{{ ind + 1 }}.</h4>
                    </div>
                    <div :class="$style.draggableSocials">
                      <el-form-item
                        label="Текст ссылки"
                        class="drag-disabled"
                        :prop="
                          'footer.bottomLinks.' +
                          index +
                          '.items.' +
                          ind +
                          '.text'
                        "
                        :rules="rules.footer.bottomLinks.items.text"
                      >
                        <el-input v-model="linkItem.text" />
                      </el-form-item>
                      <el-form-item
                        label="Ссылка"
                        class="drag-disabled"
                        :prop="
                          'footer.bottomLinks.' +
                          index +
                          '.items.' +
                          ind +
                          '.link'
                        "
                        :rules="rules.footer.bottomLinks.items.link"
                      >
                        <el-input v-model="linkItem.link" />
                      </el-form-item>
                      <div :class="$style.removeButton">
                        <el-button
                          type="danger"
                          plain
                          circle
                          icon="el-icon-delete"
                          :class="$style.remove"
                          @click="
                            removeItem('footer-bottom-menu-link', index, ind)
                          "
                        />
                      </div>
                    </div>
                  </Draggable>
                </Container>
                <div :class="$style.addElem">
                  <el-button
                    size="small"
                    type="primary"
                    :class="$style.button"
                    @click="addItem('footer-bottom-menu-link', index)"
                    >Добавить пункт меню</el-button
                  >
                </div>
              </div>
              <div :class="$style.buttons">
                <el-button
                  type="danger"
                  plain
                  circle
                  icon="el-icon-delete"
                  :class="$style.remove"
                  @click="removeItem('footer-column-bottom-menu', index)"
                />
              </div>
            </Draggable>
          </Container>
          <div :class="$style.addElem">
            <el-button
              size="small"
              type="primary"
              :class="$style.button"
              @click="addItem('footer-column-bottom-menu')"
              >Добавить блок</el-button
            >
          </div>
        </DropdownBlock>
      </DropdownBlock>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'
import DropdownBlock from '@/components/atoms/DropdownBlock.vue'

export default {
  components: {
    Icon,
    Uploader,
    DropdownBlock,
  },
  mixins: [notifications],
  async created() {
    await this.getContent()
  },

  data() {
    return {
      specialButtonsOptions: [
        { type: 'modal', text: 'Модальное окно' },
        { type: 'link', text: 'Ссылка' },
      ],
      modalTypes: [
        { type: 'CTA', text: 'Заказать звонок' },
        { type: 'FEEDBACK', text: 'Обратная связь' },
      ],
      isShowHeaderBlock: false,
      isShowFeedbacksBlock: false,
      footerBlock: {
        isShowBlock: false,
        isShowButtons: false,
        isShowSocials: false,
        isShowInformation: false,
        isShowTopMenu: false,
        isShowBottomMenu: false,
      },
      iconOptions: [
        'instagram',
        'facebook',
        'youtube',
        'telegram',
        'whatsAppLogo',
      ],
      form: {
        footer: {
          companyInfo: '',
          inn: '',
          ogrnip: '',
          socials: [],
          specialButtons: [],
          topLinks: [],
          bottomLinks: [],
        },
        header: {
          feedbackModalTitle: '',
          navigation: [],
          phone: {
            caption: '',
            link: '',
            phone: '',
          },
        },
        reviewsSection: {
          reviews: [],
          title: '',
        },
      },
      rules: {
        header: {
          feedbackModalTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
          phone: {
            phone: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
            link: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
          workingHours: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
          navigation: {
            link: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              {
                pattern: regexp.nuxtLinkUrl,
                message: 'Введен недопустимый символ',
                trigger: 'change',
              },
              {
                pattern: regexp.linkStartSlash,
                message: 'Ссылка должна начинаться с символа /',
                trigger: 'change',
              },
            ],
            text: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
          },
        },
        reviewsSection: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          reviews: {
            image: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            jobTitle: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            name: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            text: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            socials: {
              iconName: [
                {
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                },
              ],
              link: [
                {
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                },
                {
                  pattern: regexp.url,
                  message: 'Введен недопустимый символ',
                  trigger: 'change',
                },
              ],
            },
          },
        },
        footer: {
          companyInfo: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          inn: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogrnip: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          socials: {
            iconName: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            link: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
              {
                pattern: regexp.url,
                message: 'Введен недопустимый символ',
                trigger: 'change',
              },
            ],
          },
          specialButtons: {
            type: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            text: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
            link: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
          },
          topLinks: {
            items: {
              text: [
                {
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                },
              ],
              link: [
                {
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                },
                {
                  pattern: regexp.linkStartSlash,
                  message: 'Ссылка должна начинаться с символа /',
                  trigger: 'change',
                },
              ],
            },
          },
          bottomLinks: {
            items: {
              text: [
                {
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                },
              ],
              link: [
                {
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur',
                },
                {
                  pattern: regexp.linkStartSlash,
                  message: 'Ссылка должна начинаться с символа /',
                  trigger: 'change',
                },
              ],
            },
          },
        },
      },
    }
  },
  methods: {
    uploadImages(images, index) {
      this.form.reviewsSection.reviews[index].image = images.length
        ? images[0].original
        : ''
    },
    addMenuElem() {
      this.form.header.navigation.push({
        link: '',
        text: '',
      })
    },
    addReviewElem() {
      this.form.reviewsSection.reviews.push({
        name: '',
        jobTitle: '',
        image: '',
        text: '',
        socials: [],
      })
    },
    addIconForReview(index) {
      this.form.reviewsSection.reviews[index].socials.push({
        iconName: '',
        link: '',
      })
    },
    addIconElem() {
      this.form.footer.socials.push({
        iconName: '',
        link: '',
      })
    },
    removeSection(index) {
      this.form.header.navigation.splice(index, 1)
    },
    removeFooterSocials(index) {
      this.form.footer.socials.splice(index, 1)
    },
    removeReviewSocials(socials, index) {
      socials.splice(index, 1)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropMenu(dropResult) {
      this.form.header.navigation = this.applyDrag(
        this.form.header.navigation,
        dropResult,
      )
    },
    onDropReviews(dropResult) {
      this.form.reviewsSection.reviews = this.applyDrag(
        this.form.reviewsSection.reviews,
        dropResult,
      )
    },
    onDropReviewSocials(index, dropResult) {
      this.form.reviewsSection.reviews[index].socials = this.applyDrag(
        this.form.reviewsSection.reviews[index].socials,
        dropResult,
      )
    },
    onDropFooterSocials(dropResult) {
      this.form.footer.socials = this.applyDrag(
        this.form.footer.socials,
        dropResult,
      )
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get('common', 'addwine')
      loading.close()

      if (error) return

      value.content.footer.topLinks =
        value.content.footer.topLinks?.map((item) => ({
          items: item,
          isShow: false,
        })) ?? []

      value.content.footer.bottomLinks =
        value.content.footer.bottomLinks?.map((item) => ({
          items: item,
          isShow: false,
        })) ?? []

      value.content.footer.socials = value.content.footer.socials ?? []

      value.content.footer.specialButtons =
        value.content.footer.specialButtons ?? []

      this.form = value.content
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (!valid) {
          this.isShowHeaderBlock = true
          this.isShowFeedbacksBlock = true
          this.footerBlock.isShowBlock = true
          this.showNotification('Проверьте заполненные данные', 'error')
          return
        }

        const loading = this.$loading({
          lock: true,
        })

        this.form.footer.topLinks = this.form.footer.topLinks?.map(
          (i) => i.items,
        )

        this.form.footer.bottomLinks = this.form.footer.bottomLinks?.map(
          (i) => i.items,
        )

        const { error } = await delivery.ContentServiceCore.PagesActions.update(
          'common',
          'addwine',
          this.form,
        )
        loading.close()

        if (error) return

        this.showNotification('Страница успешно отредактирована', 'success')

        this.getContent()
      })
    },
    addItem(field, index) {
      switch (field) {
        case 'footer-button': {
          this.form.footer.specialButtons.push({
            type: 'modal',
            text: '',
            link: '',
          })
          break
        }

        case 'footer-column-top-menu': {
          if (this.form.footer?.topLinks?.length < 4) {
            this.form.footer.topLinks.push({ items: [], isShow: false })
            break
          }

          this.showNotification('Нельзя добавить больше 4-х колонок', 'error')
          break
        }

        case 'footer-column-bottom-menu': {
          if (this.form.footer?.bottomLinks?.length < 2) {
            this.form.footer.bottomLinks.push({ items: [], isShow: false })
            break
          }

          this.showNotification('Нельзя добавить больше 2-х колонок', 'error')
          break
        }

        case 'footer-bottom-menu-link': {
          this.form.footer.bottomLinks[index]?.items?.push({
            text: '',
            link: '',
          })
          break
        }

        case 'footer-top-menu-link': {
          this.form.footer.topLinks[index]?.items?.push({ text: '', link: '' })
          break
        }
      }
    },
    removeItem(field, index, elIndex) {
      switch (field) {
        case 'footer-button': {
          this.form.footer.specialButtons.splice(index, 1)
          break
        }

        case 'footer-column-top-menu': {
          this.form.footer.topLinks.splice(index, 1)
          break
        }

        case 'footer-column-bottom-menu': {
          this.form.footer.bottomLinks.splice(index, 1)
          break
        }

        case 'footer-bottom-menu-link': {
          this.form.footer.bottomLinks[index].items.splice(elIndex, 1)
          break
        }

        case 'footer-top-menu-link': {
          this.form.footer.topLinks[index].items.splice(elIndex, 1)
          break
        }

        case 'review': {
          this.form.reviewsSection.reviews.splice(index)
          break
        }
      }
    },
    onDrop(dropResult, field, index) {
      switch (field) {
        case 'footer-button': {
          this.form.footer.specialButtons = this.applyDrag(
            this.form.footer.specialButtons,
            dropResult,
          )
          break
        }

        case 'footer-column-top-menu': {
          this.form.footer.topLinks = this.applyDrag(
            this.form.footer.topLinks,
            dropResult,
          )
          break
        }

        case 'footer-column-bottom-menu': {
          this.form.footer.bottomLinks = this.applyDrag(
            this.form.footer.bottomLinks,
            dropResult,
          )
          break
        }

        case 'footer-bottom-menu-link': {
          this.form.footer.bottomLinks[index].items = this.applyDrag(
            this.form.footer.bottomLinks[index].items,
            dropResult,
          )
          break
        }

        case 'footer-top-menu-link': {
          this.form.footer.topLinks[index].items = this.applyDrag(
            this.form.footer.topLinks[index].items,
            dropResult,
          )
          break
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.iconOptions > div > div > ul {
  li {
    padding-top: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      path {
        fill: $gray;
      }
    }
  }
}

.common {
  .form {
    padding: 2rem;
    .dropdownBlock > div:last-child {
      .title {
        h3 {
          width: 10rem;
        }
        display: flex;
        .icon {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.5rem;
          cursor: pointer;
          &.arrow {
            margin-right: 1rem;
            transition: transform 0.3s ease;
            transform: rotate(-180deg);
            &.active {
              transition: transform 0.3s ease;
              transform: rotate(-90deg);
            }
          }
        }
      }
      .body {
        .row {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          &:not(:last-child) {
            border-bottom: 0.063rem solid $gray;
          }
          .title {
            display: flex;
            align-items: center;
          }
          .buttons {
            margin-left: 1rem;
            display: flex;
            .remove {
              margin-left: 1rem;
            }
          }
        }
      }
      .innerBlock {
        .addElem {
          margin-top: 0.5rem;
        }
      }
    }
  }
  .uploaderWrapper {
    display: flex;
  }
  .draggableSocials {
    display: flex;
    padding: 0.5rem 0;
  }
  .removeButton {
    padding-left: 1rem;
  }
  .reviewers {
    display: grid;
    grid-template-columns: 1rem 2fr 2.5fr 0.5fr;
    .remove {
      margin-top: 3rem;
      width: 3rem;
      height: 3rem;
    }
  }
  .centerIcon {
    margin-top: calc(50% - 1.25rem);
  }
  .dragIcon {
    margin-left: 1rem;
  }
  .addElem {
    display: flex;
    align-items: center;
    padding: 0 0 1rem 1rem;
    h3 {
      padding-right: 1rem;
    }
  }

  h5 {
    padding: 1rem 0;
  }
  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .name {
    text-align: center;
    padding: 1rem 0;
  }

  .menuBlock {
    .menuItem {
      display: flex;
      align-items: center;

      input {
        width: 70vw;
      }

      .removeElem {
        margin-top: -1rem;
        padding: 0 1rem;
      }
    }
  }
}
</style>
